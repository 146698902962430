import ShareButton from 'components/Share/Button'
import useFetchArticles from 'hooks/hook-fetch-articles'
import { fullUrl } from 'lib/formatter/url'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { color, remCalc } from 'styles/mixins'

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`

const StyledShareButton = styled(ShareButton)`
  padding: ${(props) => remCalc(...[0, props.iconPadding])};
  width: ${(props) => remCalc(props.iconSize)};
  height: auto;

  svg {
    width: ${(props) => remCalc(props.iconSize)};
    height: ${(props) => remCalc(props.iconSize)};
    display: block;
    margin: 0 auto;

    path {
      opacity: 1;
      fill: ${(props) => props.iconColor};
    }
  }

  a {
    display: inline;
  }

  ${(props) =>
    props.showIconText &&
    css`
      a:after {
        display: block;
        width: ${remCalc(59)};
        font-size: ${remCalc(13)};
        line-height: ${remCalc(15)};
        margin: ${remCalc(9)} auto 0;
        text-align: center;
        color: ${color.solidgray};
        content: '${props.iconText}';
      }
    `}
`

const SharePaneColor = ({
  className,
  iconSize,
  iconPadding,
  color: propColor,
  showIconText,
  show: isShow,
  entityUuid,
}) => {
  const articles = useFetchArticles(entityUuid)
  const article = get(articles, `processedNode[${entityUuid}]`, {})
  const { headline, socialHeadline, urlAlias, shortURL } = article
  const shareTitle = socialHeadline || headline
  const shareUrl = shortURL || fullUrl(urlAlias)

  const shareItem = {
    text: encodeURIComponent(shareTitle),
    url: shareUrl,
  }
  return (
    <StyledWrapper className={className}>
      {isShow.facebook && (
        <StyledShareButton
          shareItem={shareItem}
          iconSize={iconSize}
          type={'facebook'}
          iconText="Facebook"
          iconColor={propColor.facebook}
          iconPadding={iconPadding}
          showIconText={showIconText}
        />
      )}
      {isShow.twitter && (
        <StyledShareButton
          shareItem={shareItem}
          iconSize={iconSize}
          type={'twitter'}
          iconText="Twitter"
          iconColor={propColor.twitter}
          iconPadding={iconPadding}
          showIconText={showIconText}
        />
      )}
      {isShow.whatsapp && (
        <StyledShareButton
          shareItem={shareItem}
          iconSize={iconSize}
          type={'whatsapp'}
          iconText="Whatsapp"
          iconColor={propColor.whatsapp}
          iconPadding={iconPadding}
          showIconText={showIconText}
        />
      )}
      {isShow.telegram && (
        <StyledShareButton
          shareItem={shareItem}
          iconSize={iconSize}
          type={'telegram'}
          iconText="Telegram"
          iconColor={propColor.telegram}
          iconPadding={iconPadding}
          showIconText={showIconText}
        />
      )}
      {isShow.link && (
        <StyledShareButton
          shareItem={shareItem}
          iconSize={iconSize}
          type={'link'}
          iconText="Copy Link"
          iconColor={propColor.link}
          iconPadding={iconPadding}
          showIconText={showIconText}
        />
      )}
    </StyledWrapper>
  )
}

SharePaneColor.defaultProps = {
  color: {
    whatsapp: color.whatsapp,
    telegram: color.telegram,
    facebook: color.facebook,
    twitter: color.twitter,
    link: color.muddywhite,
  },
  show: {
    whatsapp: true,
    telegram: true,
    facebook: true,
    twitter: true,
    link: true,
  },
  iconSize: 59,
  iconPadding: 14,
  showIconText: true,
}

SharePaneColor.propTypes = {
  color: PropTypes.shape({
    whatsapp: PropTypes.string,
    telegram: PropTypes.string,
    facebook: PropTypes.string,
    twitter: PropTypes.string,
    link: PropTypes.string,
  }),
  show: PropTypes.shape({
    whatsapp: PropTypes.bool,
    telegram: PropTypes.bool,
    facebook: PropTypes.bool,
    twitter: PropTypes.bool,
    link: PropTypes.bool,
  }),
  iconSize: PropTypes.number,
  iconPadding: PropTypes.number,
  showIconText: PropTypes.bool,
}

export default SharePaneColor
