import CustomPropTypes from 'lib/custom-prop-types'
import assign from 'lodash/assign'
import map from 'lodash/map'
import PropTypes from 'prop-types'
import { useState } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'

const ItemContainer = styled.div`
  outline: none;
`

const DefaultSlider = styled(Slider)`
  position: relative;
`

const SliderWrapper = styled.div`
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  ${(props) =>
    props.show &&
    `
  ${DefaultSlider} {
    visibility: hidden;
    &.slick-initialized {
      visibility: visible;
    }
  }
  `}
`

const Carousel = ({ className, children = [], config: propsConfig = {} }) => {
  const [loaded, setLoaded] = useState(false)
  const config = {
    lazyLoad: false,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    onInit: () => setLoaded(true),
  }

  const items = map(children, (item, index) => {
    return <ItemContainer key={`carousel-item-${index}`}>{item}</ItemContainer>
  })

  return (
    <>
      <SliderWrapper show={loaded} className={className}>
        {items.length > 0 && (
          <DefaultSlider {...assign(config, propsConfig)}>{items}</DefaultSlider>
        )}
      </SliderWrapper>
    </>
  )
}

Carousel.propTypes = {
  children: CustomPropTypes.arrayLength(2),
  config: PropTypes.object, // config doc: https://react-slick.neostack.com/docs/api
}

export default Carousel
