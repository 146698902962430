import styled from 'styled-components'
import { remCalc } from 'styles/mixins'

const Base = styled.div`
  padding: ${remCalc(16, 24, 12)};
  border-radius: ${remCalc(22)};
  height: ${remCalc(492)};
  width: calc(100% - 48px);
  position: relative;
`

const Card = (props) => {
  return (
    <>
      <Base className={props.className}>{props.children}</Base>
    </>
  )
}

export default Card
