import Carousel from 'components/Carousel'
import TopCarouselImage from 'components/Home/TopCarousel/TopCarouselImage'
import TopCarouselItem from 'components/Home/TopCarousel/TopCarouselItem'
import CustomPropTypes from 'lib/custom-prop-types'
import compact from 'lodash/compact'
import map from 'lodash/map'
import ArrowLogo from 'public/assets/svg/carousel-arrow.svg'
import { useState } from 'react'
import styled, { css } from 'styled-components'
import { remCalc, color } from 'styles/mixins'

const ImageCarousel = styled(Carousel)`
  .slick-list {
    transform: translateX(178px);
  }

  .slick-slide {
    opacity: 0;
    transition: opacity 300ms ease;
    overflow: hidden;
  }
  .slick-active {
    opacity: 1;

    + .slick-slide,
    + .slick-slide + .slick-slide {
      opacity: 1;
    }
  }
`

const ContentCarousel = styled(Carousel)`
  position: relative;
  transform: translateY(-104%);
  margin-top: ${remCalc(23)};
  pointer-events: none;
  background: linear-gradient(90deg, ${color['yellowPear-70']} 37%, ${color.yellowPear0} 50%);
  height: ${remCalc(580)};

  ${(props) =>
    props.theme.isReport &&
    css`
      background: linear-gradient(90deg, ${color['seafoam-70']} 37%, ${color.seafoam0} 50%);
    `}

  ${(props) =>
    props.theme.isLearn &&
    css`
      background: linear-gradient(90deg, ${color['lavender-70']} 37%, ${color.lavender0} 50%);
    `}

  ${(props) =>
    props.theme.isSpark &&
    css`
      background: linear-gradient(90deg, ${color['lightPurple-70']} 37%, ${color.lightPurple0} 50%);
    `}

  .slick-list {
    transform: translateX(-339px);
  }

  .slick-slide {
    opacity: 0;
    transition: opacity 500ms ease;
  }
  .slick-active {
    opacity: 1;
    pointer-events: auto;
  }
`

const ContentItem = styled.div`
  width: ${remCalc(352)};
  margin: ${remCalc(0, 50)};
  height: ${remCalc(445)};
`

const ArrowWrapper = styled.button`
  top: 0;
  transform: none;
  z-index: 1;
  height: 100%;
  width: calc(50% - 539px);

  &:before {
    content: none;
  }
`

const LeftArrow = styled(ArrowWrapper)`
  left: 0;
  cursor: url('/yp/assets/svg/arrow-cursor-left.svg'), auto;

  &.slick-disabled {
    cursor: none;
  }
`

const RightArrow = styled(ArrowWrapper)`
  right: 0;
  cursor: url('/yp/assets/svg/arrow-cursor-right.svg'), auto;

  &.slick-disabled {
    cursor: none;
  }
`

const ArrowLeft = (props) => {
  return <LeftArrow {...props}>Previous Item</LeftArrow>
}

const ArrowRight = (props) => {
  return <RightArrow {...props}>Next Item</RightArrow>
}

const ContentArrowWrapper = styled.button`
  position: absolute;
  background: transparent;
  height: ${remCalc(69)};
  width: ${remCalc(92)};
  margin: ${remCalc(0, 10)};
  pointer-events: auto;
  bottom: 40px;
  top: auto;
  z-index: 5;
  transition: opacity 300ms ease;

  &:before {
    content: none;
  }

  &.slick-disabled {
    opacity: 0.2;
  }
`

const ContentLeftArrow = styled(ContentArrowWrapper)`
  transform: translateX(-525px);
  left: 50%;
  svg {
    transform: rotate(180deg);
  }
`

const ContentRightArrow = styled(ContentArrowWrapper)`
  transform: translateX(-413px);
  left: 50%;
  right: auto;
`

const ContentArrowLeft = (props) => {
  return (
    <ContentLeftArrow {...props}>
      <ArrowLogo />
    </ContentLeftArrow>
  )
}

const ContentArrowRight = (props) => {
  return (
    <ContentRightArrow {...props}>
      <ArrowLogo />
    </ContentRightArrow>
  )
}

const TopCarouselDesktop = ({ className, entityUuids }) => {
  const [slider1, setSlider1] = useState(null)
  const [slider2, setSlider2] = useState(null)
  const CarouselSettingsImage = {
    centerMode: true,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    variableWidth: true,
    adaptiveHeight: true,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
    ref: (slider) => setSlider2(slider),
    asNavFor: slider1,
  }

  const CarouselSettingsContent = {
    centerMode: true,
    infinite: false,
    speed: 500,
    asNavFor: slider2,
    slidesToShow: 1,
    variableWidth: true,
    adaptiveHeight: true,
    ref: (slider) => setSlider1(slider),
    prevArrow: <ContentArrowLeft />,
    nextArrow: <ContentArrowRight />,
  }

  const contentItems = map(entityUuids, (entityUuid, index) => (
    <ContentItem key={`content-item-key-${index}`}>
      <TopCarouselItem entityUuid={entityUuid} />
    </ContentItem>
  ))

  const imageItem = compact(
    map(entityUuids, (entityUuid, index) => {
      if (entityUuid) {
        return (
          <TopCarouselImage key={`image-item-${index}`} entityUuid={entityUuid} isDesktop={true} />
        )
      }
      return false
    }),
  )

  return (
    <>
      <div className={className}>
        <ImageCarousel config={CarouselSettingsImage}>{imageItem}</ImageCarousel>
        <ContentCarousel config={CarouselSettingsContent}>{contentItems}</ContentCarousel>
      </div>
    </>
  )
}

TopCarouselDesktop.propTypes = {
  entityUuids: CustomPropTypes.uuidArray.isRequired,
}

export default TopCarouselDesktop
