import ArticleLink from 'components/CustomLink/ArticleLink'
import { useGa4Params } from 'hooks/hook-ga4-params'
import { setVariables } from 'lib/gtm'
import get from 'lodash/get'
import last from 'lodash/last'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { remCalc, fontFamily, color } from 'styles/mixins'

const StyledArticleLink = styled(ArticleLink)`
  cursor: pointer;
`

const Item = styled.div`
  font-family: ${fontFamily.gothic};
  font-size: ${remCalc(20)};
  line-height: ${remCalc(40)};
  font-weight: bold;
  margin-bottom: ${remCalc(4)};
  display: inline-block;
`

const Title = styled.div`
  display: inline-block;
  color: ${color.black};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const RecapSection = styled.div`
  display: inline-block;
  color: ${color.avocado};
  margin-right: ${remCalc(8)};
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    text-decoration: underline;
  }

  ${(props) =>
    props.theme.isReport &&
    css`
      color: ${color.softteal};
    `}

  ${(props) =>
    props.theme.isLearn &&
    css`
      color: ${color.bluehouse};
    `}
    
    ${(props) =>
    props.theme.isSpark &&
    css`
      color: ${color.wisteriaPurple};
    `}
`

const RecapImg = styled.img`
  display: inline-block;
  border-radius: ${remCalc(8)};
  margin: 0 ${remCalc(22)};
  vertical-align: middle;
`

const RecapItem = ({ queueItemObject }) => {
  const { pageType } = useGa4Params()
  const {
    entityUuid,
    entityId,
    headline,
    socialHeadline,
    urlAlias: articleUrlAlias,
    images,
    sections,
  } = queueItemObject

  const { name: sectionName } = last(get(sections, '[0]', []))
  const { size_64x40: { url: imageUrl = '' } = {} } = get(images, '[0]', {})

  const routeHandler = (next) => {
    // ga4 13.1.2
    setVariables({
      event: 'GA4_tracking',
      category: 'recirculation',
      subcategory: 'widget',
      action: 'click',
      customized_parameters: {
        widget_name: 'top_stories_recap',
        article_id: entityId,
        destination_url: articleUrlAlias,
        page_type: pageType,
      },
    })

    setVariables({
      event: 'tracking',
      tracking: {
        category: 'Top storeis recap',
        action: 'Top storeis recap/Click',
        label: entityId,
      },
      eventCallback: next,
      eventTimeout: 1000,
    })
  }

  return (
    <>
      <StyledArticleLink
        article={{
          entityUuid,
          urlAlias: articleUrlAlias,
        }}
        routeHandler={routeHandler}
      >
        <Item className="show-me">
          <RecapSection>
            {' '}
            {'//'} {sectionName}
          </RecapSection>
          <Title>
            {socialHeadline || headline}
            <RecapImg src={imageUrl} />
          </Title>
        </Item>
      </StyledArticleLink>
    </>
  )
}

RecapItem.propTypes = {
  queueItemObject: PropTypes.object,
}

export default RecapItem
