import ArticleLevelLabel from 'components/ArticleLevel/ArticleLevelLabel'
import ArticleLink from 'components/CustomLink/ArticleLink'
import CustomDate from 'components/Fields/CustomDate'
import Card from 'components/Home/CardView/Card'
import CardFooter from 'components/Home/CardView/CardFooter'
import PopupShare from 'components/Home/CardView/PopupShare'
import Link from 'components/Link'
import useFetchArticles from 'hooks/hook-fetch-articles'
import CustomPropTypes from 'lib/custom-prop-types'
import { fullUrl } from 'lib/formatter/url'
import { setVariables } from 'lib/gtm'
import get from 'lodash/get'
import head from 'lodash/head'
import pick from 'lodash/pick'
import PropTypes from 'prop-types'
import StackIcon from 'public/assets/svg/card-stack.svg'
import ShareIcon from 'public/assets/svg/share.svg'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import OutsideClickHandler from 'react-outside-click-handler'
import useWebShare from 'react-use-web-share'
import styled from 'styled-components'
import { remCalc, fontFamily, color } from 'styles/mixins'

const FirstCard = styled(Card)`
  background: linear-gradient(180deg, ${color['black-10']} 0%, ${color['black-60']} 60%),
    url(${({ backgroundImageSrc }) => backgroundImageSrc});
  background-size: cover;
  background-position: center;
  padding: ${remCalc(16, 16, 12)};
  width: calc(100% - 32px);
`

const ContentWrapper = styled.div`
  position: absolute;
  bottom: ${remCalc(178)};
  color: ${color.white};
`

const Title = styled.h2`
  font-size: ${remCalc(32)};
  line-height: ${remCalc(34)};
  margin: ${remCalc(16, 0, 21)};
  font-family: ${fontFamily.gothic};
  color: ${color.white};
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;

  a {
    text-decoration: none;
    color: ${color.white};

    &:hover {
      text-decoration: underline;
    }
  }
`

const AuthorFooter = styled.span`
  display: block;
  margin-top: ${remCalc(31)};
  font-size: ${remCalc(12)};
  line-height: ${remCalc(14)};
  font-weight: 500;

  a {
    color: ${color.white};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  img {
    border-radius: 50%;
    display: inline-block;
    padding-right: ${remCalc(2)};
    vertical-align: middle;
    width: ${remCalc(24)};
    height: ${remCalc(24)};
  }
`

const StyledCustomDate = styled(CustomDate)`
  font-size: ${remCalc(14)};
  letter-spacing: ${remCalc(0.5)};
  line-height: ${remCalc(18)};
  font-weight: bold;
`

const Stack = styled(StackIcon)`
  background: ${color.sunshineyellow};
  position: absolute;
  top: ${remCalc(16)};
  right: ${remCalc(16)};
  padding: ${remCalc(11)};
  border-radius: 50%;
  display: none;
`

const ReactionElement = styled.span`
  cursor: pointer;
  border: solid ${remCalc(1)} ${color['white-60']};
  width: ${remCalc(48)};
  height: ${remCalc(48)};
  box-sizing: border-box;
  display: block;
  padding: ${remCalc(13, 12, 14, 13)};
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
`

const ShareElement = styled(ReactionElement)`
  svg {
    height: ${remCalc(20)};
    width: ${remCalc(23)};
  }

  &.active {
    border: 2px solid ${color.white};
    padding: ${remCalc(12, 11, 13, 12)};
    pointer-events: none;
  }
`

const ReactionWrap = styled.div`
  text-align: center;
  display: flex;
  width: ${remCalc(48)};
  position: absolute;
  right: ${remCalc(16)};
  bottom: ${remCalc(112)};
`

const StyledLabel = styled(ArticleLevelLabel)`
  color: ${(props) => props.theme.highlightOppositeColor};
  user-select: none;
  pointer-events: none;
`

const FrontCard = ({ className, entityUuid, showImage = true, readFullHandler }) => {
  const articles = useFetchArticles(entityUuid)
  const article = get(articles, `processedNode[${entityUuid}]`, {})

  const {
    headline,
    socialHeadline,
    authors,
    publishedDate,
    images = [],
    urlAlias,
    entityId,
  } = article
  const { portrait: { url: cardImageUrl } = {} } = head(images) || {}
  const {
    name: authorName,
    urlAlias: authorUrl,
    image: authorImage,
  } = get(authors, '[0]', {}) || {}
  const authorImageUrl = get(authorImage, 'url')

  const [shareStatus, setShare] = useState(false)

  const shareTitle = socialHeadline || headline
  const shareUrl = fullUrl(urlAlias)

  const { isSupported, share } = useWebShare()
  const shareBtnClick = () => {
    if (isSupported && isMobile) {
      share({
        title: shareTitle,
        url: shareUrl,
      })
    } else {
      setShare(!shareStatus)
    }
  }

  return (
    <>
      <FirstCard className={className} backgroundImageSrc={showImage ? cardImageUrl : false}>
        <Stack />
        <ContentWrapper>
          <StyledLabel entityUuid={entityUuid} />
          <Title>
            <ArticleLink
              article={pick(article, ['entityUuid', 'urlAlias'])}
              routeHandler={(next) => {
                setVariables({
                  event: 'tracking',
                  tracking: {
                    category: 'Card',
                    action: `Card/Click/Title/${entityId}`,
                    label: headline,
                  },
                  eventCallback: next,
                })
                setVariables({
                  event: 'GA4_tracking',
                  category: 'recirculation',
                  subcategory: 'widget',
                  action: 'click',
                  customized_parameters: {
                    widget_name: 'card',
                    page_type: 'homepage',
                    article_id: entityId,
                    destination_url: urlAlias,
                  },
                })
              }}
            >
              <a>{socialHeadline || headline}</a>
            </ArticleLink>
          </Title>
          <StyledCustomDate date={publishedDate} withTime={false}></StyledCustomDate>
          {authorImage && (
            <AuthorFooter>
              <img src={authorImageUrl} /> by<> </>
              {authorUrl ? (
                <Link href={authorUrl}>
                  <a>{authorName}</a>
                </Link>
              ) : (
                <span>{authorName}</span>
              )}
            </AuthorFooter>
          )}
        </ContentWrapper>
        <ReactionWrap>
          <ShareElement onClick={() => shareBtnClick()} className={`${shareStatus && 'active'}`}>
            <ShareIcon />
          </ShareElement>
        </ReactionWrap>
        <CardFooter
          showImage={showImage}
          hideAuthor={true}
          entityUuid={entityUuid}
          readFullHandler={readFullHandler}
        />
        <OutsideClickHandler
          useCapture={false}
          disabled={!shareStatus}
          onOutsideClick={() => setShare(false)}
        >
          <PopupShare entityUuid={entityUuid} className={`${shareStatus && 'active'}`} />
        </OutsideClickHandler>
      </FirstCard>
    </>
  )
}

FrontCard.propTypes = {
  entityUuid: CustomPropTypes.uuid.isRequired,
  showImage: PropTypes.bool,
}

export default FrontCard
