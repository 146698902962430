import styled, { css } from 'styled-components'
import { remCalc, color } from 'styles/mixins'

const StyledButton = styled.button`
  border: 2px solid ${color.black};
  border-radius: ${remCalc(4)};
  background-color: ${color.white};
  box-shadow: 0 3px 0 0 ${color.black};
  font-weight: 700;
  padding: ${remCalc(10, 18)};
  font-size: ${remCalc(14)};
  line-height: ${remCalc(16)};
  text-transform: uppercase;
  cursor: pointer;

  &.large {
    border-radius: ${remCalc(8)};
    box-shadow: 0 ${remCalc(8)} 0 0 ${color.black};
    padding: ${remCalc(12, 12, 14)};
    font-size: ${remCalc(16)};
    line-height: ${remCalc(22)};

    &.black {
      margin-bottom: ${remCalc(3)};
      box-shadow: none;
      margin: ${remCalc(-2, -2, 3)};
      border-radius: ${remCalc(8)};
      padding: ${remCalc(14, 14, 11)};
    }
  }

  &.black {
    background: ${color.black};
    color: ${color.white};
    margin-bottom: ${remCalc(3)};
    box-shadow: none;
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`

const StyledBlackButtonWrapper = styled.div`
  ${(props) =>
    props.isBlack &&
    css`
      background: ${color.black};
      display: inline-block;
      border: ${remCalc(3)} solid ${color.black};
      border-radius: ${remCalc(8)};
      background-color: ${color.white};
    `}
`

const Button = ({ isBlack, className, children, onClickCallback = () => {} }) => {
  return (
    <StyledBlackButtonWrapper isBlack={isBlack}>
      <StyledButton onClick={onClickCallback} className={className}>
        {children}
      </StyledButton>
    </StyledBlackButtonWrapper>
  )
}

export default Button
