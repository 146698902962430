import Footer from 'components/Footer'
import Header from 'components/Header'
import get from 'lodash/get'
import throttle from 'lodash/throttle'
import { useRef } from 'react'
import styled from 'styled-components'
import { remCalc, respondTo } from 'styles/mixins'

const Wrapper = styled.div`
  height: 500px;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-perspective: 300px;
  perspective: 300px;
  -webkit-perspective-origin-x: 100%;
  perspective-origin-x: 100%;
`

const ParallaxContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transform-origin-x: 100%;
  transform-origin-x: 100%;
  padding-top: ${remCalc(173)};

  ${respondTo.mdAndUp`
    padding-top: ${remCalc(154)};  
  `}
`

const StyledHeader = styled(Header)`
  position: fixed;
`

const StyledFooter = styled(Footer)`
  z-index: 10;
  position: relative;
`

const HomeParallaxWrapper = (props) => {
  const ParallaxRef = useRef()
  const HeaderRef = useRef()

  const ParallaxScroll = () => {
    const scrollTop = get(ParallaxRef, 'current.scrollTop')
    const Header = get(HeaderRef, 'current')
    Header.parallaxSticky({ scrollTop })
  }
  return (
    <>
      <StyledHeader ref={HeaderRef} />
      <Wrapper
        ref={ParallaxRef}
        onScroll={throttle(() => ParallaxScroll(), 900)}
        className={props.className}
      >
        <ParallaxContainer>
          {props.children}
          <StyledFooter />
        </ParallaxContainer>
      </Wrapper>
    </>
  )
}

export default HomeParallaxWrapper
