import ArticleLink from 'components/CustomLink/ArticleLink'
import useFetchArticles from 'hooks/hook-fetch-articles'
import CustomPropTypes from 'lib/custom-prop-types'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { remCalc, color } from 'styles/mixins'

const ImageItem = styled.a`
  margin: ${remCalc(0, 12, 30)};
  box-shadow: 0 ${remCalc(12, 24)} 0 ${color['black-20']};
  border-radius: ${remCalc(20)};
  overflow: hidden;
  display: block;
  background-image: url(${({ imageSrc }) => imageSrc});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  ${({ isDesktop }) =>
    isDesktop
      ? css`
          width: ${remCalc(856)};
          height: ${remCalc(543)};
        `
      : css`
          width: ${remCalc(327)};
          height: ${remCalc(360)};
          border-radius: ${remCalc(24)};
          background-color: ${color.white};
          box-shadow: 0 ${remCalc(12, 40)} 0 ${color['black-20']};
          max-width: 100%;
          margin: 0;
        `}
`

const TopCarouselImage = ({ entityUuid, isDesktop }) => {
  const articles = useFetchArticles(entityUuid)
  const { urlAlias, images } = get(articles, `processedNode[${entityUuid}]`, {})

  const imageSrc = get(images, `[0].${!isDesktop ? 'portrait' : 'landscape'}.url`)

  return (
    <>
      {imageSrc && (
        <ArticleLink article={{ entityUuid, urlAlias }}>
          <ImageItem imageSrc={imageSrc} isDesktop={isDesktop} />
        </ArticleLink>
      )}
    </>
  )
}

TopCarouselImage.propTypes = {
  entityUuid: CustomPropTypes.uuid.isRequired,
  isDesktop: PropTypes.bool,
}

export default TopCarouselImage
