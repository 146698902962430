import SharePaneColor from 'components/Share/SharePaneColor'
import styled from 'styled-components'
import { remCalc, fontFamily, color, respondTo } from 'styles/mixins'

const ShareWrapper = styled.div`
  display: block;
  background: ${color.white};
  position: absolute;
  bottom: ${remCalc(-67)};
  left: ${remCalc(-12)};
  right: ${remCalc(-12)};
  height: ${remCalc(116)};
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.25);
  border-radius: ${remCalc(16)};
  padding: ${remCalc(24, 20)};
  transform: translateY(-60px) scale(0.5);
  transition: all 0.15s ease-in-out;
  opacity: 0;
  transform-origin: 80% 80%;
  z-index: 1;
  visibility: hidden;
  pointer-events: none;

  &.active {
    opacity: 1;
    transform: translateY(0) scale(1);
    visibility: visible;
    pointer-events: auto;
  }

  span {
    display: block;
    font-family: ${fontFamily.gothic};
    margin-bottom: ${remCalc(16)};
    font-size: ${remCalc(16)};
    line-height: ${remCalc(19)};
    font-weight: bold;
  }

  &:after {
    bottom: 100%;
    right: ${remCalc(40)};
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent;
    border-bottom-color: ${color.white};
    border-width: ${remCalc(12)};
    margin-bottom: ${remCalc(-5)};
  }
`

const MobileSharePaneColor = styled(SharePaneColor)`
  ${respondTo.mdAndUp`
    display: none;
  `}
`

const DesktopSharePaneColor = styled(SharePaneColor)`
  ${respondTo.smAndDown`
    display: none;
  `}
`

const sharePaneButtonToShowOnMobile = {
  whatsapp: true,
  telegram: false,
  facebook: true,
  twitter: true,
  link: true,
}

const sharePaneButtonToShowOnDesktop = {
  whatsapp: false,
  telegram: true,
  facebook: true,
  twitter: true,
  link: true,
}

const PopupShare = ({ className, entityUuid }) => {
  return (
    <>
      <ShareWrapper className={className}>
        <span>Share this Article</span>
        <MobileSharePaneColor
          show={sharePaneButtonToShowOnMobile}
          iconPadding={0}
          entityUuid={entityUuid}
        />
        <DesktopSharePaneColor
          show={sharePaneButtonToShowOnDesktop}
          iconPadding={0}
          entityUuid={entityUuid}
        />
      </ShareWrapper>
    </>
  )
}

export default PopupShare
