import RecapItem from 'components/Home/Recap/RecapItem'
import ParallaxGroup from 'components/Parallax/ParallaxGroup'
import useFetchQueueItems from 'hooks/hook-fetch-queue-items'
import chunk from 'lodash/chunk'
import map from 'lodash/map'
import PropTypes from 'prop-types'
import Marquee from 'react-double-marquee'
import styled, { ThemeProvider } from 'styled-components'
import { remCalc, respondTo, fontFamily } from 'styles/mixins'

const RecapTitle = styled.h2`
  font-size: ${remCalc(32)};
  font-weight: bold;
  line-height: ${remCalc(36)};
  text-align: center;
  font-family: ${fontFamily.gothic};
  margin: ${remCalc(0, 0, 32)};
  text-transform: uppercase;

  ${respondTo.mdAndUp`
    font-size: ${remCalc(40)};
    font-weight: bold;
    line-height: ${remCalc(36)};
    letter-spacing: ${remCalc(1.2)};
    margin-bottom: ${remCalc(50)};
  `}
`

const ParallaxRecap = styled(ParallaxGroup)`
  height: ${remCalc(470)};
  padding-top: ${remCalc(64)};
  z-index: 5;
  background: ${(props) => props.theme.bgColor};

  ${respondTo.mdAndUp`
    height: ${remCalc(460)};
    padding-top: ${remCalc(98)};
  `}
`

const RecapMarqueeContainer = styled.div`
  padding-top: 10px;
  width: 100vw;
  white-space: nowrap;
  ${respondTo.smAndDown`
    &:last-child {
      display: none;
    }
  `}
`

const Recap = ({
  theme = {},
  title = '',
  queueName = '',
  numberOfEachRow = 1,
  numberOfRow = 1,
}) => {
  const { context: articlesEntityUuids } = useFetchQueueItems({
    queueName,
    offset: 0,
    limit: numberOfEachRow * numberOfRow,
    ssr: true,
  })

  const recapList = (dataArray, keyPrefix) =>
    map(dataArray, (data, index) => (
      <RecapItem key={`${keyPrefix}-${index}`} queueItemObject={data} />
    ))

  const recapMarqueeList = map(
    chunk(articlesEntityUuids, numberOfEachRow),
    (chunkEntityUuids, index) => (
      <RecapMarqueeContainer key={`recap-marquee-list-${index}`}>
        <Marquee speed={index % 2 === 0 ? 0.02 : 0.05} direction="left">
          {recapList(chunkEntityUuids, `recap-marquee-${index}`)}
        </Marquee>
      </RecapMarqueeContainer>
    ),
  )

  return (
    <>
      <ThemeProvider theme={theme}>
        <ParallaxRecap>
          <RecapTitle>{title}</RecapTitle>
          {recapMarqueeList}
        </ParallaxRecap>
      </ThemeProvider>
    </>
  )
}

Recap.propTypes = {
  queueName: PropTypes.string,
  title: PropTypes.string,
  numberOfEachRow: PropTypes.number,
  numberOfRow: PropTypes.number,
}

export default Recap
