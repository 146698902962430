import ArticleLevelLabel from 'components/ArticleLevel/ArticleLevelLabel'
import CustomDate from 'components/Fields/CustomDate'
import Card from 'components/Home/CardView/Card'
import Link from 'components/Link'
import useFetchArticles from 'hooks/hook-fetch-articles'
import CustomPropTypes from 'lib/custom-prop-types'
import get from 'lodash/get'
import AssignmentIcon from 'public/assets/svg/assignment.svg'
import { useContext } from 'react'
import styled, { ThemeProvider, ThemeContext } from 'styled-components'
import { remCalc, fontFamily, color } from 'styles/mixins'
import { description2PlainText } from 'utils/common'

const ACard = styled(Card)`
  background: linear-gradient(180deg, ${color['black-10']} 0%, ${color['black-40']} 71.48%),
    url('/yp/assets/png/assignment.jpg');
  background-size: cover;
  background-position: center;
  padding: ${remCalc(16, 16, 12)};
  width: calc(100% - 32px);
  margin-top: ${remCalc(30)};
  box-shadow: 0 ${remCalc(12, 40)} 0 ${color['black-20']};

  a.section-link {
    color: ${color.sunshineyellow};
  }
`

const ContentWrapper = styled.div`
  position: absolute;
  bottom: ${remCalc(48)};
  left: ${remCalc(16)};
  right: ${remCalc(16)};
  color: ${color.white};
  pointer-events: none;
`

const Title = styled.h2`
  font-size: ${remCalc(32)};
  line-height: ${remCalc(34)};
  margin: ${remCalc(16, 0, 16)};
  font-family: ${fontFamily.gothic};
  color: ${color.white};
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
`

const DateDisplay = styled(CustomDate)`
  font-size: ${remCalc(14)};
  letter-spacing: ${remCalc(0.5)};
  line-height: ${remCalc(18)};
  font-weight: bold;
`

const IconWrap = styled.div`
  position: absolute;
  top: ${remCalc(16)};
  right: ${remCalc(16)};
  padding: ${remCalc(7, 11, 6)};
  border-radius: 50%;
  background: ${color.seafoam};
`

const Assignment = styled(AssignmentIcon)`
  width: ${remCalc(18)};
`

const Description = styled.div`
  font-size: ${remCalc(18)};
  line-height: ${remCalc(26)};
  margin: ${remCalc(16, 0)};
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
`

const Wrapper = styled.div`
  display: block;
  width: 100%;
  max-width: ${remCalc(320)};
  height: ${remCalc(547)};
  max-height: ${remCalc(547)};
  margin: 0 auto ${remCalc(45)};
`

const ArticleClick = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

const AssignmentSections = styled(ArticleLevelLabel)`
  pointer-events: auto;
`

const AssignmentCard = ({ className, entityUuid = '' }) => {
  const themeContext = useContext(ThemeContext)

  const articles = useFetchArticles(entityUuid)
  const article = get(articles, `processedNode[${entityUuid}]`, {})
  const { publishedDate, headline, socialHeadline, urlAlias, summary } = article

  return (
    <>
      <Wrapper>
        <ACard className={className}>
          <IconWrap>
            <Assignment />
          </IconWrap>
          <ArticleClick href={urlAlias} />
          <ContentWrapper>
            <ThemeProvider theme={{ highlightColor: themeContext.highlightBgColor }}>
              <AssignmentSections entityUuid={entityUuid} />
            </ThemeProvider>
            <Title>{socialHeadline || headline}</Title>
            <Description>{description2PlainText(summary)}</Description>
            <DateDisplay date={publishedDate} withTime={false} />
          </ContentWrapper>
        </ACard>
      </Wrapper>
    </>
  )
}

AssignmentCard.propTypes = {
  entityUuid: CustomPropTypes.uuid,
}

export default AssignmentCard
