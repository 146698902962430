import TopCarouselDesktop from 'components/Home/TopCarousel/TopCarouselDesktop'
import TopCarouselMobile from 'components/Home/TopCarousel/TopCarouselMobile'
import ParallaxGroup from 'components/Parallax/ParallaxGroup'
import CustomPropTypes from 'lib/custom-prop-types'
import { useState, useEffect } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { remCalc, respondTo } from 'styles/mixins'

const ParallaxTop = styled(ParallaxGroup)`
  height: ${remCalc(628)};
  z-index: 8;
  overflow: hidden;
  opacity: 0;

  &.show {
    opacity: 1;
  }

  ${respondTo.mdAndUp`
    height: ${remCalc(600)};
    padding-top: ${remCalc(20)};
  `}

  background: linear-gradient(180deg, ${(props) => props.theme.bgColor} 95%, ${(props) =>
    props.theme.bgColorZero} 100%);
`

const DesktopCarousel = styled(TopCarouselDesktop)`
  ${respondTo.smAndDown`
    display: none;
  `}
  @media (max-width: 1079px) {
    transform: scale(0.9);
    transform-origin: top;
  }
`

const MobileCarousel = styled(TopCarouselMobile)`
  ${respondTo.mdAndUp`
    display: none;
  `}
`

const TopCarousel = ({ theme = {}, entityUuids = [] }) => {
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    setLoaded(true)
  }, [])

  return (
    <>
      <ThemeProvider theme={theme}>
        <ParallaxTop className={loaded ? 'show' : 'hidden'}>
          {entityUuids.length > 1 && (
            <>
              <MobileCarousel entityUuids={entityUuids} />
              <DesktopCarousel entityUuids={entityUuids} />
            </>
          )}
        </ParallaxTop>
      </ThemeProvider>
    </>
  )
}

TopCarousel.propTypes = {
  entityUuids: CustomPropTypes.uuidArray.isRequired,
}

export default TopCarousel
