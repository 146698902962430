import styled from 'styled-components'

const Group = styled.div`
  position: relative;
  height: 500px;
  height: 100vh;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
`

const ParallaxGroup = (props) => {
  return (
    <>
      <Group className={props.className}>{props.children}</Group>
    </>
  )
}

export default ParallaxGroup
