import { useQuery } from '@apollo/client'
import { GET_ARTICLES_BY_ENTITY_UUID, GET_ARTICLES_BY_ENTITY_UUID_LITE } from 'apollo/queries/cs'
import isArray from 'lodash/isArray'
import merge from 'lodash/merge'
import reduce from 'lodash/reduce'
import nodeHandler from 'utils/node/node-handler'

const useFetchArticles = (entityUuids, limit = 5, mode = 'lite') => {
  const QUERY = mode === 'lite' ? GET_ARTICLES_BY_ENTITY_UUID_LITE : GET_ARTICLES_BY_ENTITY_UUID

  const { data: { articles = [] } = {} } = useQuery(QUERY, {
    variables: {
      entityUuids: isArray(entityUuids) ? entityUuids : [entityUuids],
      limit,
    },
    notifyOnNetworkStatusChange: true,
    ssr: true,
  })
  return reduce(
    articles,
    (reduceResult, node) => {
      return merge(reduceResult, nodeHandler(node))
    },
    { length: articles.length },
  )
}

export default useFetchArticles
