import BaseCard from 'components/Home/CardView/BaseCard'
import FrontCard from 'components/Home/CardView/FrontCard'
import useFetchArticles from 'hooks/hook-fetch-articles'
import CustomPropTypes from 'lib/custom-prop-types'
import { setVariables } from 'lib/gtm'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import join from 'lodash/join'
import map from 'lodash/map'
import { useState } from 'react'
import styled from 'styled-components'
import { remCalc, color } from 'styles/mixins'

const Wrapper = styled.div`
  display: block;
  width: 100%;
  max-width: ${remCalc(320)};
  height: ${remCalc(547)};
  max-height: ${remCalc(547)};
  margin: 0 auto ${remCalc(45)};
  position: relative;
  -webkit-tap-highlight-color: transparent;
  transition: transform 300ms ease;

  &:hover {
    transform: scale(1.05);
    z-index: 10;
  }

  > div {
    position: absolute;
    transform-origin: top;
    transition:
      opacity 300ms,
      transform 300ms,
      visibility 300ms;
    opacity: 0;
    bottom: 0;
    transform: scale(0.7) translateY(-45px);
    visibility: hidden;
  }
  .previous {
    z-index: 6;
    opacity: 0;
    transform: scale(1) translateY(600px);
    transition:
      opacity 500ms,
      transform 1.5s,
      visibility 1.5s;
    visibility: hidden;
    pointer-events: disabled;
  }
  .current {
    z-index: 5;
    opacity: 1;
    box-shadow: 0 ${remCalc(12, 40)} 0 ${color['black-20']};
    transform: scale(1) translateY(0);
    visibility: visible;
  }
  .positiontwo {
    transform: scale(0.9) translateY(-17px);
    z-index: 4;
    opacity: 0.6;
    visibility: visible;
  }
  .positionthree {
    transform: scale(0.8) translateY(-35px);
    z-index: 3;
    opacity: 0.2;
    visibility: visible;
    transition:
      opacity 300ms,
      transform 300ms,
      visibility 300ms;
    pointer-events: auto;
  }
`

const NextCardButton = styled.button`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: ${remCalc(90)};
  color: transparent;
  background: none;
  border: none;
  width: 100%;
  z-index: 10000;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: transparent;

  &.last-active {
    bottom: ${remCalc(320)};

    &:after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: ${remCalc(-235)};
      content: '';
      height: ${remCalc(80)};
    }
  }

  &.first-active {
    bottom: ${remCalc(380)};

    &:after {
      position: absolute;
      left: 0;
      right: ${remCalc(130)};
      bottom: ${remCalc(-295)};
      content: '';
      height: ${remCalc(80)};
    }
  }
`
const createSlide = ({ subHeadline, blockquote }) => {
  const slides = [
    {
      title: 'Index',
    },
  ]

  if (!isEmpty(subHeadline)) {
    //TODO better handle style in #103
    slides.push({
      title: 'Takeaways',
      contentType: 'subheading',
    })
  }
  if (blockquote) {
    const quote = join(blockquote, '</br>')
    if (quote) {
      slides.push({
        title: 'Highlight',
        content: `<blockquote>${quote}</blockquote>`,
        contentType: 'blockquote',
      })
    }
  }

  return slides
}

const CardsWrapper = ({ entityUuid, showImage = false }) => {
  const articles = useFetchArticles(entityUuid)
  const article = get(articles, `processedNode[${entityUuid}]`, {})
  const { subHeadline, extraData, entityId } = article

  const slides = createSlide({ subHeadline, blockquote: get(extraData, 'blockquote[0]', []) })
  const [currentIndex, setCurrent] = useState(0)
  const [nextIndex, setNext] = useState(1)
  const [secondIndex, setSecond] = useState(2)
  const [previousIndex, setPrevious] = useState(slides.length - 1)

  let trackingCardType = 'Headline'
  switch (get(slides, `[${currentIndex}].contentType`)) {
    case 'subheading':
      trackingCardType = 'Subheadline'
      break
    case 'reactions':
      trackingCardType = 'Reaction'
      break
  }

  const readFullHandler = (next) => {
    setVariables({
      event: 'tracking',
      tracking: {
        category: 'Card',
        action: `Card/Click/Read the full article/${entityId}`,
        label: trackingCardType,
      },
      eventCallback: next,
    })
  }

  const cards = map(slides, (item, index) => {
    return index == 0 ? (
      <FrontCard
        key={`front-card-${index}`}
        className={`${previousIndex == index && 'previous'}
        ${currentIndex == index && 'current'}
        ${secondIndex == index && 'positionthree'}
        ${nextIndex == index && 'positiontwo'}`}
        contentType={item.contentType}
        entityUuid={entityUuid}
        showImage={showImage}
        readFullHandler={readFullHandler}
      />
    ) : (
      <BaseCard
        key={`base-card-${index}`}
        content={item.content}
        contentType={item.contentType}
        title={item.title}
        count={index + 1}
        length={slides.length}
        entityUuid={entityUuid}
        className={`${previousIndex == index && 'previous'}
        ${currentIndex == index && 'current'}
        ${secondIndex == index && 'positionthree'}
        ${nextIndex == index && 'positiontwo'}`}
        showImage={showImage}
        readFullHandler={readFullHandler}
      />
    )
  })

  const nextCard = () => {
    setVariables({
      event: 'tracking',
      tracking: {
        category: 'Card',
        action: 'Card/Click/Swipe',
        label: trackingCardType,
      },
    })
    setVariables({
      event: 'GA4_tracking',
      category: 'recirculation',
      subcategory: 'widget_swipe',
      action: 'click',
      customized_parameters: {
        widget_name: 'card',
        article_id: entityId,
        card: trackingCardType?.toLowerCase(),
      },
    })

    if (currentIndex == slides.length - 1) {
      setCurrent(0)
    } else {
      setCurrent(currentIndex + 1)
    }

    if (nextIndex == slides.length - 1) {
      setNext(0)
    } else {
      setNext(nextIndex + 1)
    }

    if (previousIndex == slides.length - 1) {
      setPrevious(0)
    } else {
      setPrevious(previousIndex + 1)
    }

    if (slides.length === 3) {
      setTimeout(() => {
        if (secondIndex == slides.length - 1) {
          setSecond(0)
        } else {
          setSecond(secondIndex + 1)
        }
      }, 400)
    } else {
      if (secondIndex == slides.length - 1) {
        setSecond(0)
      } else {
        setSecond(secondIndex + 1)
      }
    }
  }

  return (
    <>
      <Wrapper>
        {cards}
        <NextCardButton
          className={`${currentIndex == 0 && 'first-active'}
                      ${currentIndex == slides.length - 1 && 'last-active'}`}
          onClick={() => nextCard()}
        >
          Next Card
        </NextCardButton>
      </Wrapper>
    </>
  )
}

CardsWrapper.propTypes = {
  entityUuid: CustomPropTypes.uuid.isRequired,
}

export default CardsWrapper
