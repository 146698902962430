import { Col, Grid, Row } from '@product/react-styled-flexboxgrid'
import AdvertSlot from 'components/Advert/AdvertSlot'
import Button from 'components/Button'
import Lazyload from 'components/Common/LazyLoad'
import AssignmentCard from 'components/Home/CardView/AssignmentCard'
import CardsWrapper from 'components/Home/CardView/CardsWrapper'
import ParallaxGroup from 'components/Parallax/ParallaxGroup'
import CustomPropTypes from 'lib/custom-prop-types'
import { setVariables } from 'lib/gtm'
import reduce from 'lodash/reduce'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import Masonry from 'react-masonry-component'
import styled from 'styled-components'
import { color, fontFamily, remCalc, respondTo } from 'styles/mixins'

const ForegroundTitleContainer = styled.div`
  position: relative;
  text-align: center;
  margin: ${remCalc(56, 0, 24)};
  ${respondTo.mdAndUp`
    margin: ${remCalc(101, 0, 36)};
  `}
`

const ForegroundTitleBackground = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: ${remCalc(425)};
  font-family: ${fontFamily.gothic};
  font-size: ${remCalc(90)};
  font-style: normal;
  font-weight: 700;
  letter-spacing: ${remCalc(2.25)};
  line-height: ${remCalc(80)};
  color: transparent;
  opacity: 0.08;
  -webkit-text-stroke: ${remCalc(1)} ${color.black};
  text-transform: uppercase;
  z-index: -1;

  ${respondTo.mdAndUp`
    min-width: ${remCalc(1135)};
    font-size: ${remCalc(180)};
    letter-spacing: ${remCalc(4.5)};
    line-height: ${remCalc(150)};
    -webkit-text-stroke: ${remCalc(2)} ${color.black};
  `}
`

const ForegroundTitle = styled.h2`
  font-size: ${remCalc(32)};
  font-weight: bold;
  line-height: ${remCalc(36)};
  text-align: center;
  font-family: ${fontFamily.gothic};
  text-transform: uppercase;

  ${respondTo.mdAndUp`
    font-size: ${remCalc(48)};
    font-weight: bold;
    line-height: ${remCalc(48)};
    letter-spacing: ${remCalc(1.2)};
  `}
`

const MasonryItem = styled.div.attrs({
  className: 'grid-item',
})`
  width: 100%;
  height: ${remCalc(592)};
  position: relative;

  ${respondTo.md`
    width: 50%;
  `}

  ${respondTo.lg`
    width: 33%;
  `}
`

const MasonryItemPlaceholder = styled(MasonryItem)`
  display: none;
  ${respondTo.mdAndUp`
    height: ${remCalc(150)};
    display: block;
  `}
  ${respondTo.md`
    &:first-child {
      height: 0;
    }
  `}
`

const MasonryItemAdvertWrapper = styled(MasonryItem)`
  min-height: ${remCalc(350)};
  height: auto;
  display: block;
  margin-top: ${remCalc(30)};
  margin-bottom: ${remCalc(48)};
`

const CardMasonry = styled(Masonry)`
  ${respondTo.xs`
    width: 100vw;
  `}

  ${respondTo.smAndUp`
    margin: ${remCalc(0, 20)};
  `}
`

const CardGroup = styled(ParallaxGroup)`
  height: auto;
`

const StyledCol = styled(Col)`
  width: 100%;
`

const ButtonCenter = styled.div`
  display: block;
  text-align: center;
  margin: ${remCalc(0, 0, 96)};
  ${respondTo.smAndUp`
    margin: ${remCalc(40, 0, 108)};
  `}
`

const LoadMoreButton = styled(Button).attrs({
  className: 'black large',
})`
  width: ${remCalc(263)};
  height: ${remCalc(58)};

  color: ${color.white};
  font-family: ${fontFamily.roboto};
  font-size: ${remCalc(16)};
  font-weight: bold;
  letter-spacing: 0;
  line-height: ${remCalc(22)};
`

const masonryOptions = {
  percentPosition: true,
  itemSelector: '.grid-item',
  gutter: 0,
}

const mobileTitleMapping = {
  Worksheets: 'Work sheets',
}

const getTitle = (title) => {
  if (isMobile) {
    return mobileTitleMapping[title] ?? title
  }
  return title
}

const CardView = ({
  className,
  entityUuids = [],
  isAssignments,
  title = '',
  advertOption = {},
  showLoadMoreButton = true,
  loadMoreCallback = () => {},
}) => {
  const { zone: advertZone = '', index: advertIndex = 0, count: advertCount = 0 } = advertOption

  const [showImage, setShowImage] = useState(false)
  const [foregroundTitleBackground, setForegroundTitleBackground] = useState('')

  useEffect(() => {
    setForegroundTitleBackground(getTitle(title))
  }, [title])

  const cardItems = reduce(
    entityUuids,
    (result, entityUuid, index) => {
      const cardItem = isAssignments ? (
        <AssignmentCard entityUuid={entityUuid} />
      ) : (
        <CardsWrapper entityUuid={entityUuid} showImage={showImage} />
      )
      // handle first card
      if (index === 0) {
        result.push(<MasonryItemPlaceholder key={`masonry-item-placeholder-top-${index}`} />)
      } else if (
        advertCount > 0 &&
        index % advertIndex === 0 &&
        index / advertIndex >= advertCount
      ) {
        result.push(
          <MasonryItemAdvertWrapper key={`masonry-item-advert-wrapper-${index}`}>
            <AdvertSlot width={300} height={250} advertZone={advertZone} slot={'lrec1'} />
          </MasonryItemAdvertWrapper>,
        )
      }
      result.push(
        <MasonryItem id={`check-me-${index}`} key={`card-item-${index}`}>
          {cardItem}
        </MasonryItem>,
      )

      // handle first card
      if (index === 0)
        result.push(<MasonryItemPlaceholder key={`masonry-item-placeholder-bottom-${index}`} />)

      return result
    },
    [],
  )

  const cardGroupImpressionFired = useRef(false)
  const inViewCallback = () => {
    setShowImage(true)
    if (!cardGroupImpressionFired.current) {
      setVariables({
        event: 'GA4_tracking',
        category: 'recirculation',
        subcategory: 'widget',
        action: 'imp',
        customized_parameters: {
          widget_name: 'card',
          page_type: 'homepage',
        },
      })
      cardGroupImpressionFired.current = true
    }
  }

  return (
    <>
      <CardGroup className={className}>
        <div>
          <ForegroundTitleContainer>
            <ForegroundTitle>{title}</ForegroundTitle>
            <ForegroundTitleBackground>{foregroundTitleBackground}</ForegroundTitleBackground>
          </ForegroundTitleContainer>
          <Lazyload showChildren={true} inViewCallback={inViewCallback}></Lazyload>
          <Grid>
            <Row>
              <StyledCol
                xs={16}
                sm={16}
                md={16}
                lg={14}
                xsOffset={0}
                smOffset={0}
                mdOffset={0}
                lgOffset={1}
              >
                <CardMasonry options={masonryOptions}>{cardItems}</CardMasonry>
                <ButtonCenter>
                  {showLoadMoreButton && (
                    <LoadMoreButton isBlack={'true'} onClickCallback={loadMoreCallback}>
                      Load More
                    </LoadMoreButton>
                  )}
                </ButtonCenter>
              </StyledCol>
            </Row>
          </Grid>
        </div>
      </CardGroup>
    </>
  )
}

CardView.propTypes = {
  entityUuids: CustomPropTypes.uuidArray.isRequired,
  title: PropTypes.string,
  isAssignments: PropTypes.bool,
  loadMoreCallback: PropTypes.func,
  showLoadMoreButton: PropTypes.bool,
  advertOption: PropTypes.object,
}

export default CardView
