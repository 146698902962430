import Subheading from 'components/Fields/Subheading'
import Card from 'components/Home/CardView/Card'
import CardFooter from 'components/Home/CardView/CardFooter'
import useFetchArticles from 'hooks/hook-fetch-articles'
import CustomPropTypes from 'lib/custom-prop-types'
import get from 'lodash/get'
import head from 'lodash/head'
import PropTypes from 'prop-types'
import QuoteIcon from 'public/assets/svg/quote.svg'
import styled from 'styled-components'
import { remCalc, color, fontFamily } from 'styles/mixins'

const BasicCard = styled(Card)`
  background: linear-gradient(180deg, ${color['black-90']} 5%, ${color['black-80']} 10%),
    url('${({ articleImageUrl }) => articleImageUrl}');
  background-size: cover;
  background-position: center;

  a.section-link {
    color: ${color.sunshineyellow};
  }
`

const CardTitle = styled.div`
  font-family: ${fontFamily.gothic};
  color: ${color.white};
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${remCalc(10)};
  border-bottom: 1px solid ${color.anothergray};
`

const Title = styled.div`
  font-size: ${remCalc(17)};
  line-height: ${remCalc(21)};
  text-transform: uppercase;
`

const CardPosition = styled.div`
  font-size: ${remCalc(14)};
  line-height: ${remCalc(17)};

  span {
    font-size: ${remCalc(24)};
    line-height: ${remCalc(29)};
  }
`

const StyledSubheading = styled(Subheading)`
  & > * {
    line-height: ${remCalc(24)};
    font-size: ${remCalc(18)};
    margin-bottom: ${remCalc(24)};
    position: relative;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    padding-left: ${remCalc(32)};
    position: relative;
    margin-bottom: ${remCalc(24)};
    &:before {
      background: url('/yp/assets/svg/finger-yellow.svg');
      content: '';
      display: block;
      width: ${remCalc(19)};
      height: ${remCalc(16)};
      position: absolute;
      left: 0;
      top: ${remCalc(5)};
    }
  }
`

const MainContent = styled.div`
  color: ${color.white};
  position: absolute;
  top: ${remCalc(56)};
  height: ${remCalc(380)};
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: ${remCalc(24)};
  line-height: ${remCalc(28)};
  font-family: ${fontFamily.gothic};
  font-weight: bold;
  max-width: calc(100% - 48px);
  width: 100%;

  blockquote {
    margin: 0 0 ${remCalc(32)};
    font-size: ${remCalc(26)};
    line-height: ${remCalc(30)};
  }
`

const BlockQuoteIcon = styled(QuoteIcon)`
  height: ${remCalc(43)};
  width: ${remCalc(48)};
  margin-bottom: ${remCalc(12)};
`

const BaseCard = ({
  className,
  entityUuid,
  contentType,
  content,
  title,
  count,
  length,
  showImage = true,
  readFullHandler,
}) => {
  const articles = useFetchArticles(entityUuid)
  const article = get(articles, `processedNode[${entityUuid}]`, {})

  const { images } = article

  const { portrait: { url: articleImageUrl } = {} } = head(images) || {}

  return (
    <>
      <BasicCard articleImageUrl={showImage ? articleImageUrl : false} className={className}>
        <CardTitle>
          <Title>{title}</Title>
          <CardPosition>
            <span>{count}</span> of {length}
          </CardPosition>
        </CardTitle>
        <MainContent>
          {contentType == 'subheading' && (
            <StyledSubheading mode={'html'} entityUuid={entityUuid} />
          )}
          {contentType == 'blockquote' && content && (
            <div>
              <BlockQuoteIcon />
              <span dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          )}
        </MainContent>
        <CardFooter
          showImage={showImage}
          hideAuthor={true}
          entityUuid={entityUuid}
          readFullHandler={readFullHandler}
        />
      </BasicCard>
    </>
  )
}

BaseCard.propTypes = {
  entityUuid: CustomPropTypes.uuid.isRequired,
  contentType: PropTypes.string.isRequired,
  content: PropTypes.string,
  title: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  showImage: PropTypes.bool,
}

export default BaseCard
