import ArticleLink from 'components/CustomLink/ArticleLink'
import useFetchArticles from 'hooks/hook-fetch-articles'
import CustomPropTypes from 'lib/custom-prop-types'
import { setVariables } from 'lib/gtm'
import get from 'lodash/get'
import head from 'lodash/head'
import pick from 'lodash/pick'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { remCalc, color } from 'styles/mixins'

const ContentFooter = styled.a`
  display: block;
  padding-top: ${remCalc(8)};
  border-top: 1px solid ${color.anothergray};
  position: absolute;
  bottom: ${remCalc(12)};
  width: calc(100% - 32px);
  color: ${color.anothergray};
  left: ${remCalc(16)};
  cursor: pointer;

  &:hover {
    text-decoration: underline;

    b {
      text-decoration: underline;
    }
  }
`

const FooterInner = styled.div`
  float: left;
  padding-top: ${remCalc(10)};

  b {
    font-size: ${remCalc(16)};
    font-weight: 700;
    line-height: ${remCalc(18)};
    color: ${color.sunshineyellow};
    display: block;
  }

  span {
    font-size: ${remCalc(13)};
    line-height: ${remCalc(18)};
    display: block;
    margin-top: ${remCalc(8)};
  }
`

const FooterImage = styled.div`
  float: right;
  border-radius: ${remCalc(8)};
  height: ${remCalc(64)};
  width: ${remCalc(64)};
  background-image: url(${({ imageSrc }) => imageSrc});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`

const CardFooter = ({ hideAuthor, entityUuid, showImage = true, readFullHandler }) => {
  const readMinute = false //TODO implement when data ready

  const articles = useFetchArticles(entityUuid)
  const article = get(articles, `processedNode[${entityUuid}]`, {})

  const { entityId, authors, images, textAuthorByline, urlAlias } = article
  const { square: { url: articleSquareImageUrl } = {} } = head(images) || {}
  const { authorName } = head(authors) || {}

  const handleReadFullArticle = () => {
    setVariables({
      event: 'GA4_tracking',
      category: 'recirculation',
      subcategory: 'widget',
      action: 'click',
      customized_parameters: {
        widget_name: 'card',
        page_type: 'homepage',
        article_id: entityId,
        destination_url: urlAlias,
      },
    })
  }

  return (
    <>
      <ArticleLink
        article={pick(article, ['entityUuid', 'urlAlias'])}
        routerMiddleware={(next) => readFullHandler(next)}
      >
        <ContentFooter>
          <FooterInner>
            <b onClick={handleReadFullArticle}>Read the full article</b>
            <span>
              {readMinute ? `${readMinute} mins Read | ` : ''}
              {!hideAuthor &&
                (authorName || textAuthorByline) &&
                (textAuthorByline ? textAuthorByline : `by ${authorName}`)}
            </span>
          </FooterInner>
          <FooterImage imageSrc={showImage ? articleSquareImageUrl : false} />
        </ContentFooter>
      </ArticleLink>
    </>
  )
}

CardFooter.propTypes = {
  hideAuthor: PropTypes.bool,
  entityUuid: CustomPropTypes.uuid.isRequired,
  showImage: PropTypes.bool,
}

export default CardFooter
