import { useRouter } from 'next/router'

const pageTypeMapping = {
  '/_render/home': 'homepage',
  '/_render/topicpage': 'topic',
  '/_render/sectionpage': 'section',
  '/_render/article': 'article',
  '/_render/learn': 'section',
  '/_render/report': 'section',
}

export const useGa4Params = () => {
  const router = useRouter()
  const pageType = pageTypeMapping[router?.pathname]

  return {
    pageType,
  }
}
