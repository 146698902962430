import ArticleLevelLabel from 'components/ArticleLevel/ArticleLevelLabel'
import ArticleLink from 'components/CustomLink/ArticleLink'
import Link from 'components/Link'
import useFetchArticles from 'hooks/hook-fetch-articles'
import CustomPropTypes from 'lib/custom-prop-types'
import get from 'lodash/get'
import pick from 'lodash/pick'
import styled from 'styled-components'
import { remCalc, color, fontFamily, respondTo } from 'styles/mixins'

const Wrapper = styled.div`
  ${respondTo.mdAndUp`
    height: ${remCalc(534)};
    margin-top: ${remCalc(80)};
  `}

  ${respondTo.smAndDown`
    margin-top: ${remCalc(24)};
    padding: 0 ${remCalc(8)};
  `}

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

const Title = styled.h2`
  font-size: ${remCalc(38)};
  line-height: ${remCalc(44)};
  margin: ${remCalc(12, 0, 16)};
  font-family: ${fontFamily.gothic};
  color: ${color.black};

  ${respondTo.smAndDown`
    font-size: ${remCalc(24)};
    line-height: ${remCalc(28)};
    margin: ${remCalc(10, 0, 12)};
  `}

  a {
    text-decoration: none;
    color: ${color.black};
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }
`

const AuthorFooter = styled.span`
  display: block;

  ${respondTo.smAndDown`
    font-size: ${remCalc(14)};
    line-height: ${remCalc(32)};
    margin-bottom: ${remCalc(5)};
  `}

  a {
    color: ${color.black};
  }

  img {
    border-radius: 50%;
    display: inline-block;
    padding-right: ${remCalc(2)};
    vertical-align: middle;
  }
`

const Time = styled.span`
  display: block;
  color: ${color.solidgray};
  font-size: ${remCalc(12)};
  letter-spacing: ${remCalc(0.5)};
  line-height: ${remCalc(22)};
  margin: ${remCalc(12, 0, 14)};

  ${respondTo.mdAndUp`
    display: none;
  `}
`

const TopCarouselItem = ({ entityUuid }) => {
  const articles = useFetchArticles(entityUuid)
  const article = get(articles, `processedNode[${entityUuid}]`, {})

  const { headline, socialHeadline, urlAlias: url, readTime = '', authors = [{}] } = article
  const { name: authorName, urlAlias: authorUrl, image: authorImage } = authors
  const authorImageUrl = get(authorImage, 'url')

  const title = socialHeadline || headline

  return (
    <>
      <Wrapper>
        <ArticleLevelLabel entityUuid={entityUuid} />
        {url && title && (
          <Title>
            <ArticleLink article={pick(article, ['entityUuid', 'urlAlias'])}>{title}</ArticleLink>
          </Title>
        )}
        {readTime && <Time>{readTime}</Time>}
        {authorImageUrl && (
          <AuthorFooter>
            <img src={authorImageUrl} /> by<> </>
            {authorUrl ? <Link href={authorUrl}>{authorName}</Link> : <>{authorName}</>}
          </AuthorFooter>
        )}
      </Wrapper>
    </>
  )
}

TopCarouselItem.propTypes = {
  entityUuid: CustomPropTypes.uuid.isRequired,
}

export default TopCarouselItem
